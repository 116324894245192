<template>
  <div
    :class="`TheHeaderMenuLayoutItem--${modifier}`"
    class="TheHeaderMenuLayoutItem"
  >
    <div class="TheHeaderMenuLayoutItem__wrapper  wrapper">
      <slot name="button" />

      <nav class="TheHeaderMenuLayoutItem__nav">
        <slot name="nav" />
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheHeaderMenuLayoutItem',

  props: {
    modifier: {
      type: String,
      default: 'main',
      validator (value) {
        return [
          'main',
          'sub',
        ].includes(value)
      },
    },
  },
}
</script>

<style lang="postcss">
.TheHeaderMenuLayoutItem {
  @screen sm {
    &--main {
      display: none;
    }
  }

  @screen smMax {
    width: 50%;

    &--main {
      opacity: 1;
      visibility: visible;
      transform: translateX(0) translateZ(0);
      transition:
        opacity theme('transitionDuration.500') theme('transitionTimingFunction.out-circ') theme('transitionDelay.250'),
        visibility theme('transitionDuration.500') theme('transitionTimingFunction.out-circ') theme('transitionDelay.250'),
        transform theme('transitionDuration.250') theme('transitionTimingFunction.out-circ') theme('transitionDelay.250');

      .TheHeader.has-submenu & {
        opacity: 0;
        visibility: hidden;
        transform: translateX(calc(-1 * theme('spacing.base-12'))) translateZ(0);
        transition:
          opacity theme('transitionDuration.250') theme('transitionTimingFunction.in-circ'),
          visibility theme('transitionDuration.250') theme('transitionTimingFunction.in-circ'),
          transform theme('transitionDuration.250') theme('transitionTimingFunction.in-circ');
      }
    }

    &--sub {
      position: relative;
      right: 50%;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transform: translateX(theme('spacing.base-12')) translateZ(0);
      transition:
        opacity theme('transitionDuration.250') theme('transitionTimingFunction.in-circ'),
        visibility theme('transitionDuration.250') theme('transitionTimingFunction.in-circ'),
        transform theme('transitionDuration.250') theme('transitionTimingFunction.in-circ');

      .TheHeader.has-submenu & {
        opacity: 1;
        visibility: visible;
        transform: translateX(0) translateZ(0);
        transition:
          opacity theme('transitionDuration.500') theme('transitionTimingFunction.out-circ') theme('transitionDelay.250'),
          visibility theme('transitionDuration.500') theme('transitionTimingFunction.out-circ') theme('transitionDelay.250'),
          transform theme('transitionDuration.250') theme('transitionTimingFunction.out-circ') theme('transitionDelay.250');
      }
    }
  }
}

.TheHeaderMenuLayoutItem__wrapper {
  @apply relative pt-base-24 pb-base-30;
  @apply opacity-0 transition-opacity duration-250 ease-smooth;

  .TheHeader.has-menu & {
    @apply opacity-100 transition-opacity duration-500 ease-smooth delay-250;
  }

  @screen sm {
    padding-top: 180px;

    @apply pb-base-90;
  }
}

.TheHeaderMenuLayoutItem__nav {
  @screen smMax {
    @apply pt-base-36;
  }
}
</style>
