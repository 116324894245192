<template>
  <header
    class="TheHeader is-light"
    :class="{
      'has-menu': isMenuOpen,
      'has-submenu': isSubMenuOpen,
      'has-scrolled': hasScrolled,
      'has-scrolled-up': hasScrolledUp,
      'has-scrolled-down': hasScrolledDown,
    }"
  >
    <TheHeaderAlertBar
      v-if="showAlertBar && alertBarText && alertBarMobileText"
      ref="alertbar"
      :short-text="alertBarMobileText"
      :long-text="alertBarText"
      @close="closeAlertBar"
    />

    <div class="TheHeader__outer">
      <div class="TheHeader__inner wrapper">
        <nav class="TheHeader__bar" role="navigation">
          <div class="TheHeader__left">
            <TheHeaderBurger
              class="TheHeader__burger"
              aria-label="Open Main Menu"
              :aria-controls="ariaMenuTitle"
              :aria-expanded="isMenuOpen"
              @click.native="toggleMenu"
            />

            <ul
              v-if="headerPrimaryLinks && headerPrimaryLinks.length"
              class="TheHeader__list TheHeader__list--left"
            >
              <li
                v-for="(primaryNav, navIndex) in headerPrimaryLinks"
                :key="`primary-${navIndex}`"
                class="TheHeader__item"
                :class="{ 'is-menu-item': primaryNav._modelApiKey !== 'link' }"
              >
                <BaseLink
                  v-if="primaryNav._modelApiKey === 'link'"
                  class="TheHeader__link t-h6"
                  :slug="
                    urlBuilder(
                      primaryNav.link.slug,
                      primaryNav.link._modelApiKey,
                      context
                    )
                  "
                  @click.native="closeMenu"
                >
                  {{ primaryNav.link.displayTitle }}
                </BaseLink>

                <ButtonMenu
                  v-else
                  aria-label="Open Shop Menu"
                  :aria-controls="ariaMenuTitle"
                  :aria-expanded="isMenuOpen"
                  :is-menu-open="isMenuOpen"
                  class="TheHeader__link t-h6"
                  @click.native="toggleMenu"
                >
                  {{ primaryNav.menuLinkText }}
                </ButtonMenu>
              </li>
            </ul>
          </div>

          <div class="TheHeader__middle">
            <TheHeaderLogo class="TheHeader__logo" @close-menu="closeMenu" />
          </div>

          <div class="TheHeader__right">
            <ul
              v-if="headerSecondaryLinks && headerSecondaryLinks.length"
              class="TheHeader__list"
            >
              <li
                v-for="(secondaryNav, navIndex) in headerSecondaryLinks"
                :key="`secondary-${navIndex}`"
                class="TheHeader__item"
              >
                <BaseLink
                  class="TheHeader__link t-h6"
                  :slug="
                    urlBuilder(
                      secondaryNav.slug,
                      secondaryNav._modelApiKey,
                      context
                    )
                  "
                  @click.native="closeMenu"
                >
                  {{ secondaryNav.displayTitle }}
                </BaseLink>
              </li>
            </ul>
            <a href="/">
              <ButtonAccounts
                aria-label="Visit Account overview"
                :is-dark="false"
                class="TheHeader__Accounts"
              />
            </a>
          </div>
        </nav>
      </div>
    </div>

    <TheHeaderMenu
      :id="ariaMenuTitle"
      :primary-links="headerPrimaryLinks"
      :secondary-links="headerSecondaryLinks"
      :quick-links="mobileQuickLinks"
      :social-links="socialLinks"
      @open-sub-menu="openSubMenu"
      @close-sub-menu="closeSubMenu"
      @close-menu="closeMenu"
    >
      <!--
          This looks ugly duplicating the alert bar but
          is the most bulletproof method for the header
          to listen to the natural height of the bar.
          This is purely a spacer which is hidden.
        -->
      <template #alertBarSpacer>
        <TheHeaderAlertBar
          v-if="showAlertBar && alertBarText && alertBarMobileText"
          :short-text="alertBarMobileText"
          :long-text="alertBarText"
          is-hidden
        />
      </template>
    </TheHeaderMenu>
  </header>
</template>

<script>
import anime from 'animejs'
import Cookies from 'js-cookie'
import throttle from 'lodash-es/throttle'

import urlBuilder from '@/utils/vue-helpers/urlBuilder'

import BaseLink from '@/components/vue/BaseLink.vue'
import ButtonAccounts from '@/components/vue/buttons/ButtonAccounts.vue'
import ButtonMenu from '@/components/vue/buttons/ButtonMenu.vue'

import TheHeaderAlertBar from './TheHeaderAlertBar.vue'
import TheHeaderBurger from './TheHeaderBurger.vue'
import TheHeaderLogo from './TheHeaderLogo.vue'
import TheHeaderMenu from './TheHeaderMenu.vue'

const { PUBLIC_CONTEXT } = import.meta.env

export default {
  name: 'TheHeader',

  components: {
    BaseLink,
    ButtonMenu,
    ButtonAccounts,
    TheHeaderAlertBar,
    TheHeaderBurger,
    TheHeaderLogo,
    TheHeaderMenu,
  },

  props: {
    headerData: {
      required: true,
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      ariaMenuTitle: 'menu',
      cartCount: 0,
      hasScrolled: false,
      isMenuOpen: false,
      isSearchOpen: false,
      isSubMenuOpen: false,
      onScrollThrottled: false,
      hasScrolledUp: false,
      hasScrolledDown: false,
      showAlertBar: false,
      showAlertBarWithoutDelay: false,
      context: PUBLIC_CONTEXT,
    }
  },

  computed: {
    alertBarText() {
      return this.headerData.alertBarText
    },
    alertBarMobileText() {
      return this.headerData.alertBarMobileText
    },
    headerPrimaryLinks() {
      return this.headerData?.headerPrimaryLinks
    },
    headerSecondaryLinks() {
      return this.headerData?.headerSecondaryLinks
    },
    mobileQuickLinks() {
      return this.headerData?.mobileQuickLinks
    },
    socialLinks() {
      return this.headerData?.socialLinks
    },

    hasDarkThemeCheck() {
      return !this.isMenuOpen && !this.isSubMenuOpen && !this.hasScrolled
    },

    hasLightThemeCheck() {
      return this.isMenuOpen || this.isSubMenuOpen
    },
  },

  mounted() {
    const closeAlertBarCookie = Cookies.get('close-alert-bar')

    this.setShowAlertBar(!closeAlertBarCookie)
    this.setShowAlertBarWithoutDelay(!closeAlertBarCookie)

    this.onScroll()
    this.initHeaderScroll()

    this.onScrollThrottled = throttle(() => {
      this.onScroll()
    }, 50)

    window.addEventListener('scroll', this.onScrollThrottled, { passive: true })

    this.$nextTick(() => {
      if (this.showAlertBarWithoutDelay) {
        const alertBar = this.$refs.alertbar

        if (alertBar) {
          document.documentElement.style.setProperty(
            '--base-alertbar-height',
            `${alertBar.$el.offsetHeight}px`
          )
        }
      }
    })
  },

  destroyed() {
    window.removeEventListener('scroll', this.onScrollThrottled, {
      passive: true,
    })

    if (this.timeout) clearTimeout(this.timeout)
  },

  methods: {
    toggleScroll(disabled) {
      if (!document) return

      const htmlEl = document.querySelector('html')
      if (!htmlEl) return

      htmlEl.classList[disabled ? 'add' : 'remove']('overflow-hidden')
    },
    urlBuilder,
    setScrolledUp(val) {
      this.hasScrolledUp = val
    },
    setScrolledDown(val) {
      this.hasScrolledDown = val
    },
    setShowAlertBar(val) {
      this.showAlertBar = val
    },
    setShowAlertBarWithoutDelay(val) {
      this.showAlertBarWithoutDelay = val
    },
    closeAlertBar() {
      // Animation to hide AlertBar
      const header = document.querySelector('.TheHeader')
      const notification = document.querySelectorAll('.TheHeaderAlertBar')

      if (!header || !notification) return

      this.setShowAlertBarWithoutDelay(false)

      const headerConfig = {
        targets: header,
        duration: 500,
        translateY: -notification[0].clientHeight,
        easing: 'easeInOutExpo',
        complete: () => {
          this.setShowAlertBar(false)
          Cookies.set('close-alert-bar', true, { expires: 14 })
          anime.set(header, { translateY: 0 })
          header.removeAttribute('style')
        },
      }

      anime(headerConfig)
    },

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen

      if (!this.isMenuOpen) this.closeSubMenu()
    },

    closeMenu() {
      this.isMenuOpen = false
      this.closeSubMenu()
    },

    openSubMenu() {
      this.isSubMenuOpen = true
    },

    closeSubMenu() {
      this.isSubMenuOpen = false
    },

    toggleSearch() {
      if (!this.isSearchOpen) {
        this.openSearch()
      } else {
        this.closeSearch()
      }
    },

    openSearch() {
      if (this.isMenuOpen) {
        this.closeMenu()

        this.timeout = setTimeout(() => {
          this.isSearchOpen = true
        }, 500)
      } else {
        this.isSearchOpen = true
      }
    },

    closeSearch() {
      this.isSearchOpen = false
      this.initHeaderScroll()
    },

    onScroll() {
      const headerHeight = 5
      const scrollPos = window.scrollY

      this.hasScrolled = scrollPos > headerHeight

      // Scrolling up
      if (this.prevScrollPos > scrollPos) {
        this.setScrolledUp(scrollPos > headerHeight)
        this.setScrolledDown(false)
        // Scrolling down
      } else {
        this.setScrolledUp(false)
        this.setScrolledDown(scrollPos > headerHeight)
      }

      this.prevScrollPos = scrollPos
    },

    initHeaderScroll() {
      if (this.hasScrolled) {
        this.setScrolledUp(true)
        this.setScrolledDown(false)
      }
    },
  },
}
</script>

<style lang="postcss">
.TheHeader {
  @apply fixed top-0 left-0 w-full z-20 pointer-events-none;
}

.TheHeader__outer {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(theme('spacing.base-24')) translateZ(0);
  transition:
    opacity theme('transitionDuration.500')
      theme('transitionTimingFunction.smooth'),
    color theme('transitionDuration.500')
      theme('transitionTimingFunction.smooth'),
    transform theme('transitionDuration.500')
      theme('transitionTimingFunction.in-out-circ');

  .TheHeader.has-scrolled & {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }

  .TheHeader:not(.has-menu).has-scrolled.has-scrolled-down & {
    opacity: 0;
    transform: translateY(-100%) translateZ(0);
    transition:
      opacity theme('transitionDuration.250')
        theme('transitionTimingFunction.smooth'),
      color theme('transitionDuration.500')
        theme('transitionTimingFunction.smooth') theme('transitionDelay.500'),
      transform theme('transitionDuration.500')
        theme('transitionTimingFunction.in-out-circ');
  }

  @screen smMax {
    .TheHeader.has-menu & {
      transform: translateY(-100%) translateZ(0);
    }
  }

  .TheHeader.is-dark & {
    @apply text-white;
  }
}

.TheHeader__bar {
  @apply relative flex items-center justify-between;

  height: var(--base-header-height);
}

.TheHeader__left,
.TheHeader__right {
  flex-basis: 50%;
}

.TheHeader__right {
  @apply flex items-center justify-end;
}

.TheHeader__list {
  @apply flex;

  &--left {
    margin-left: calc(-1 * theme('spacing.base-18') / 2);

    @screen md {
      margin-left: calc(-1 * theme('spacing.base-30') / 2);
    }
  }

  @apply smMax:hidden;
}

.TheHeader__link {
  @apply mb-0;

  padding: calc(theme('spacing.base-18') / 2);

  @screen sm {
    transition: opacity theme('transitionDuration.250')
      theme('transitionTimingFunction.smooth');

    &:hover {
      opacity: 0.6;

      .TheHeader.has-menu & {
        opacity: 1;
      }
    }

    &:active {
      opacity: 0.8;
    }
  }

  @screen md {
    padding: calc(theme('spacing.base-30') / 2);
  }
}

/* Collection of items for consistent hovers */
.TheHeader__item,
.TheHeader__logo,
.TheHeader__search,
.TheHeader__cart,
.TheHeader__accounts {
  transition: opacity theme('transitionDuration.500')
    theme('transitionTimingFunction.smooth');

  .TheHeader.has-menu &:not(.is-menu-item) {
    opacity: 0.4;

    &:hover {
      opacity: 1;
      transition: opacity theme('transitionDuration.250')
        theme('transitionTimingFunction.smooth');
    }
  }
}
</style>
