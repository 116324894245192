<template>
  <div class="TheFooterLinks" :class="{ 'is-open': isOpen }">
    <button
      :id="'accordion-header-' + title.toLowerCase()"
      type="button"
      :aria-expanded="isOpen.toString()"
      :aria-controls="'accordion-body-' + title.toLowerCase()"
      class="TheFooterLinks__switch t-para"
      @click="toggleAccordion"
    >
      <span class="TheFooterLinks__title t-para">
        {{ title }}
      </span>

      <BaseButtonIcon
        class="TheFooterLinks__arrow"
        tag="span"
        label="down"
        shape="down"
        size="large"
        modifier="ghost"
        theme="alt"
      />
    </button>

    <div
      id="accordion-body"
      class="TheFooterLinks__body t-para"
      role="region"
      :aria-labelledby="'accordion-header-' + title.toLowerCase()"
      :style="{ height: `${drawerHeight}px` }"
    >
      <ul ref="body-content" class="TheFooterLinks__content">
        <li
          v-for="(link, index) in links"
          :key="index"
          class="TheFooterLinks__item"
        >
          <BaseLink
            class="TheFooterLinks__link"
            :slug="urlBuilder(link.slug, link._modelApiKey, context)"
          >
            <span class="TheFooterLinks__text">{{
              link.title || link.displayTitle
            }}</span>
          </BaseLink>
        </li>
        <!-- TODO: remove and add external links to CMS -->
        <li v-if="hasCredit" class="TheFooterLinks__item">
          <BaseLink
            class="TheFooterLinks__link"
            tag="a"
            slug="https://dealers.biggreenegg.co.uk/"
          >
            <span class="TheFooterLinks__text">Reseller Portal</span>
          </BaseLink>
        </li>
        <li v-if="hasCredit" class="TheFooterLinks__item">
          <BaseLink
            class="TheFooterLinks__link"
            :class="{ 'has-credit': hasCredit }"
            tag="a"
            slug="https://studiorotate.com/"
          >
            <span class="TheFooterLinks__text">Rotate°</span>
          </BaseLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import urlBuilder from '@/utils/vue-helpers/urlBuilder'

import BaseLink from '@/components/vue/BaseLink.vue'
import BaseButtonIcon from '@/components/vue/buttons/BaseButtonIcon.vue'

const { PUBLIC_CONTEXT } = import.meta.env

export default {
  name: 'TheFooterLinks',

  components: {
    BaseButtonIcon,
    BaseLink,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    links: {
      type: Array,
      required: true,
    },

    hasCredit: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isOpen: false,
    drawerHeight: 0,
    context: PUBLIC_CONTEXT,
  }),

  methods: {
    urlBuilder,
    toggleAccordion() {
      if (!this.$refs['body-content']) return
      this.isOpen = !this.isOpen

      this.drawerHeight = this.isOpen
        ? this.$refs['body-content'].clientHeight
        : 0
    },
  },
}
</script>

<style lang="postcss">
.TheFooterLinks {
  --switch-pad-top: 0.75em;
  --switch-pad-bottom: 0.789em;

  @apply smMax:border-t smMax:border-solid smMax:border-white smMax:border-opacity-15;

  &:last-child {
    @apply smMax:border-b smMax:border-solid smMax:border-white smMax:border-opacity-15;
  }
}

.TheFooterLinks__switch {
  @apply sm:mb-base-6 sm:pointer-events-none;

  @apply smMax:flex smMax:items-center smMax:justify-between smMax:w-full smMax:text-left smMax:mb-0;

  @screen smMax {
    padding-top: var(--switch-pad-top);
    padding-bottom: var(--switch-pad-bottom);
  }
}

.TheFooterLinks__title {
  @apply mb-0 opacity-60;
}

.TheFooterLinks__arrow {
  margin-right: -0.35em; /* no hover and aligned to edge for mobile */
  pointer-events: none;
  transform: rotate(0deg) translateZ(0);
  transition: transform theme('transitionDuration.500')
    theme('transitionTimingFunction.in-out-circ');

  .TheFooterLinks.is-open & {
    transform: rotate(0.5turn) translateZ(0);
  }

  @apply sm:hidden;
}

.TheFooterLinks__body {
  @apply mb-0;

  @screen smMax {
    position: relative;
    top: calc(-1 * var(--switch-pad-bottom));
    overflow: hidden;
    opacity: 0;
    transform: translateY(0) translateZ(0);
    transition:
      opacity theme('transitionDuration.250')
        theme('transitionTimingFunction.smooth') 0s,
      height theme('transitionDuration.500')
        theme('transitionTimingFunction.in-out-circ')
        theme('transitionDuration.100');

    .TheFooterLinks.is-open & {
      opacity: 1;
      transition:
        opacity theme('transitionDuration.500')
          theme('transitionTimingFunction.smooth')
          theme('transitionDuration.250'),
        height theme('transitionDuration.500')
          theme('transitionTimingFunction.in-out-circ') 0s;
    }
  }

  @screen sm {
    height: auto !important; /* override style for desktop as accordion only on mobile */
  }
}

.TheFooterLinks__content {
  @screen smMax {
    padding-bottom: 1px;
  }
}

.TheFooterLinks__link {
  @apply block;

  padding-top: calc(7 / 13 * 1em);
  padding-bottom: calc(7 / 13 * 1em);
  @apply sm:py-base-6;
}

.TheFooterLinks__text {
  @apply block;

  @screen sm {
    transform: translateX(0) translateZ(0);
    transition:
      opacity theme('transitionDuration.250')
        theme('transitionTimingFunction.smooth'),
      transform theme('transitionDuration.250')
        theme('transitionTimingFunction.smooth');

    .TheFooterLinks__link:hover & {
      transform: translateX(1ch) translateZ(0);
    }
  }

  .TheFooterLinks__link.has-credit & {
    @apply opacity-60;
  }

  .TheFooterLinks__link.has-credit:hover & {
    @apply opacity-100;
  }
}
</style>
