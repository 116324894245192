<template>
  <div class="TheHeaderMenuLayout">
    <slot name="alertBarSpacer" />

    <div class="TheHeaderMenuLayout__scroll">
      <div class="TheHeaderMenuLayout__container">
        <div class="TheHeaderMenuLayout__grid">
          <slot />
        </div>
      </div>
    </div>

    <BaseOverlay
      class="TheHeaderMenuLayout__overlay"
      :is-visible="true"
      @click.native="$emit('close-menu')"
    />
  </div>
</template>

<script>
import BaseOverlay from '../vue/BaseOverlay.vue'

export default {
  name: 'TheHeaderMenuLayout',

  components: {
    BaseOverlay,
  },
}
</script>

<style lang="postcss">
.TheHeaderMenuLayout {
  @apply fixed top-0 left-0 -z-1 w-full h-full pointer-events-none;

  .TheHeader.has-menu & {
    @apply pointer-events-auto;
  }
}

.TheHeaderMenuLayout__scroll {
  height: 100%;
  overflow: hidden auto;
  -webkit-overflow-scrolling: touch;
}

.TheHeaderMenuLayout__container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
}

.TheHeaderMenuLayout__grid {
  @apply relative overflow-hidden bg-white;

  z-index: 2;
  transform: translateY(-100%) translateZ(0);

  @apply transition-transform duration-500 ease-in-out-circ;

  .TheHeader:not(.has-menu).has-scrolled & {
    transform: translateY(calc(-100% + var(--base-header-height))) translateZ(0);
  }

  .TheHeader:not(.has-menu).has-scrolled.has-scrolled-down & {
    transform: translateY(-100%) translateZ(0);
  }

  .TheHeader.has-menu & {
    transform: translateY(0) translateZ(0);
  }

  @screen smMax {
    display: flex;
    width: 200%;
  }

  &::after {
    content: '';
    height: 1px;

    @apply absolute right-0 bottom-0 left-0 bg-green bg-opacity-10;
  }
}

.TheHeaderMenuLayout__continue {
  @apply flex items-center justify-center overflow-hidden text-white mb-0;

  z-index: 2;
  flex: 1 1 0;

  @apply opacity-0 invisible transition-opacity duration-500 ease-smooth;

  transition:
    opacity theme('transitionDuration.250') theme('transitionTimingFunction.smooth'),
    visibility theme('transitionDuration.250') theme('transitionTimingFunction.smooth');

  .TheHeader.has-menu & {
    @apply opacity-100 visible;

    transition:
      opacity theme('transitionDuration.500') theme('transitionTimingFunction.smooth'),
      visibility theme('transitionDuration.500') theme('transitionTimingFunction.smooth');
  }
}

.TheHeaderMenuLayout__overlay {
  @apply opacity-0 transition-opacity duration-500 ease-smooth delay-250;

  .TheHeader.has-menu & {
    @apply opacity-100 delay-250;
  }
}
</style>
