<template>
  <footer class="TheFooter section bg-green text-white">
    <div class="TheFooter__wrapper wrapper">
      <div class="TheFooter__signup">
        <TheFooterForm class="TheFooter__form" />

        <SocialLinks theme="alt" modifier="ghost" :items="socialLinks" />
      </div>

      <div class="TheFooter__nav">
        <TheFooterLinks
          v-for="(list, listIndex) in footerContent"
          :key="listIndex"
          class="TheFooter__list"
          :title="list.title"
          :links="list.links"
          :has-credit="footerContent.length - 1 === listIndex"
        />
      </div>

      <BaseLink class="TheFooter__logo" slug="/">
        <span class="TheFooter__icon">
          <BaseSvg
            title="Big Green Egg Logo"
            icon="logo-text"
            :vb-width="208"
            :vb-height="31"
          />
        </span>
      </BaseLink>
      <p class="TheFooter__copyright t-nano">
        ©{{ year }} Alfresco Concepts, UK. The Big Green Egg logo is Trade
        Marked
      </p>
    </div>
  </footer>
</template>

<script>
import BaseLink from '../vue/BaseLink.vue'
import BaseSvg from '../vue/BaseSvg.vue'
import SocialLinks from '../vue/SocialLinks.vue'
import TheFooterForm from './TheFooterForm.vue'
import TheFooterLinks from './TheFooterLinks.vue'

export default {
  name: 'TheFooter',

  components: {
    BaseSvg,
    BaseLink,
    SocialLinks,
    TheFooterForm,
    TheFooterLinks,
  },

  props: {
    footerData: {
      required: true,
      default: () => ({}),
    },
  },

  computed: {
    footerContent() {
      return this.footerData.footerContent
    },
    socialLinks() {
      return this.footerData.socialLinks
    },
    year() {
      return new Date().getFullYear()
    },
  },
}
</script>

<style lang="postcss">
.TheFooter {
  @apply pt-base-48 pb-base-48;

  @apply sm:pt-base-90 sm:pb-base-60;
}

.TheFooter__wrapper {
  @apply md:flex md:flex-wrap;
}

.TheFooter__signup {
  @apply mb-base-36;

  @apply sm:mb-base-72;

  @apply md:w-1/2;
}

.TheFooter__form {
  @screen xs {
    max-width: 324px;
  }
}

.TheFooter__nav {
  @apply sm:flex sm:flex-wrap sm:w-full sm:mb-base-18;

  @apply md:w-1/2;
}

.TheFooter__list {
  @apply sm:w-1/3;
}

.TheFooter__logo {
  @apply w-full mt-base-54;

  @screen sm {
    margin-top: 8vw;
  }

  @apply base:mt-base-114;
}

.TheFooter__copyright {
  @apply m-auto opacity-80 mt-base-18 text-center;
  @apply sm:mt-base-30;
}

.TheFooter__icon {
  display: block;
  width: auto;
  margin-right: -0.25%;
  margin-left: -0.25%;
}
</style>
