<template>
  <TheHeaderMenuLayout @close-menu="$emit('close-menu')">
    <template #alertBarSpacer>
      <slot name="alertBarSpacer" />
    </template>

    <TheHeaderMenuLayoutItem modifier="main">
      <template #button>
        <BaseButtonIcon
          class="TheHeaderMenu__close"
          tag="button"
          label="Close Main Menu"
          shape="cross"
          size="large"
          click-area
          @click.native="$emit('close-menu')"
        />
      </template>

      <template #nav>
        <ul v-if="primaryLinks.length" class="TheHeaderMenu__list">
          <li
            v-for="(primaryNav, navIndex) in primaryLinks"
            :key="`primary-${navIndex}`"
          >
            <BaseLink
              v-if="primaryNav._modelApiKey === 'link'"
              class="TheHeaderMenu__link t-h2"
              :slug="
                urlBuilder(
                  primaryNav.link.slug,
                  primaryNav.link._modelApiKey,
                  context
                )
              "
              @click.native="$emit('close-menu')"
            >
              {{ primaryNav.link.displayTitle }}
            </BaseLink>

            <ButtonMenuMobile
              v-else
              class="TheHeaderMenu__link t-h2"
              @click.native="$emit('open-sub-menu')"
            >
              {{ primaryNav.menuLinkText }}
            </ButtonMenuMobile>
          </li>

          <li
            v-for="(secondaryNav, navIndex) in secondaryLinks"
            :key="`secondary-${navIndex}`"
          >
            <BaseLink
              class="TheHeaderMenu__link t-h2"
              :slug="
                urlBuilder(
                  secondaryNav.slug,
                  secondaryNav._modelApiKey,
                  context
                )
              "
              @click.native="$emit('close-menu')"
            >
              {{ secondaryNav.displayTitle }}
            </BaseLink>
          </li>
        </ul>

        <div class="TheHeaderMenu__quick">
          <ul v-if="quickLinks.length" class="TheHeaderMenu__quick-list">
            <li
              v-for="(quick, quickIndex) in quickLinks"
              :key="`quick-${quickIndex}`"
            >
              <BaseLink
                class="TheHeaderMenu__quick-link t-para"
                :slug="
                  urlBuilder(quick.link.slug, quick.link._modelApiKey, context)
                "
                @click.native="$emit('close-menu')"
              >
                {{ quick.link.displayTitle }}
              </BaseLink>
            </li>
          </ul>

          <SocialLinks :items="socialLinks" />
        </div>
      </template>
    </TheHeaderMenuLayoutItem>

    <TheHeaderMenuLayoutItem v-if="subMenuData" modifier="sub">
      <template #button>
        <button
          class="TheHeaderMenu__back"
          type="button"
          @click="$emit('close-sub-menu')"
        >
          <BaseButtonIcon
            class="TheHeaderMenu__back-arrow"
            tag="span"
            label="Back to Main Menu"
            shape="prev"
            size="large"
          />

          <span class="TheHeaderMenu__back-text t-h5">{{
            subMenuData.menuLinkText
          }}</span>
        </button>
      </template>

      <template #nav>
        <ul class="TheHeaderMenu__list">
          <li v-for="(link, index) in subMenuData.links" :key="index">
            <BaseLink
              class="TheHeaderMenu__link has-image t-h2"
              :class="{ 'has-nudge': index === 0 }"
              :slug="urlBuilder(link.slug, link._modelApiKey, context)"
              @click.native="$emit('close-menu')"
            >
              <div
                v-if="link.navigationImage && link.navigationImage.url"
                class="TheHeaderMenu__media"
              >
                <div class="TheHeaderMenu__media-inner">
                  <BaseImage
                    class="TheHeaderMenu__image"
                    :image="link.navigationImage"
                    :has-ratio="false"
                    :srcset="`
                      ${imgixSize(link.navigationImage.url, 'square_xxl')} 1600w,
                      ${imgixSize(link.navigationImage.url, 'square_xl')} 1200w,
                      ${imgixSize(link.navigationImage.url, 'square_lg')} 800w,
                      ${imgixSize(link.navigationImage.url, 'square_md')} 600w,
                      ${imgixSize(link.navigationImage.url, 'square_sm')} 400w,
                      ${imgixSize(link.navigationImage.url, 'square_xs')} 200w,
                      ${imgixSize(link.navigationImage.url, 'square_xxs')} 100w
                    `"
                    sizes="
                      (min-width: 800px) calc(50vw - (72 / 720 * 100vw)),
                      42px
                    "
                    :src="imgixSize(link.navigationImage.url, 'square_md')"
                  />
                </div>
              </div>

              <div class="TheHeaderMenu__text">
                {{ link.displayTitle }}

                <span class="TheHeaderMenu__hover t-h3-script">
                  {{ link.navigationText }}
                </span>
              </div>
            </BaseLink>
          </li>
        </ul>

        <footer
          v-if="
            subMenuData &&
            subMenuData.footerLinks &&
            subMenuData.footerLinks.length
          "
          class="TheHeaderMenu__footer"
        >
          <h6
            v-if="subMenuData.footerLinksTitle"
            class="TheHeaderMenu__footer-title t-micro"
          >
            {{ subMenuData.footerLinksTitle }}
          </h6>

          <ul class="TheHeaderMenu__footer-list">
            <li v-for="(link, index) in subMenuData.footerLinks" :key="index">
              <BaseLink
                class="TheHeaderMenu__footer-link t-h4"
                :slug="urlBuilder(link.slug, link._modelApiKey, context)"
                @click.native="$emit('close-menu')"
              >
                <span class="TheHeaderMenu__footer-text">
                  {{ link.displayTitle }}
                </span>
              </BaseLink>
            </li>
          </ul>
        </footer>
      </template>
    </TheHeaderMenuLayoutItem>
  </TheHeaderMenuLayout>
</template>

<script>
import { imgixSize } from '@/utils/vue-helpers/imgix'
import urlBuilder from '@/utils/vue-helpers/urlBuilder'

import SocialLinks from '@/components/vue//SocialLinks.vue'
import BaseImage from '@/components/vue/BaseImage.vue'
import BaseLink from '@/components/vue/BaseLink.vue'
import BaseButtonIcon from '@/components/vue/buttons/BaseButtonIcon.vue'
import ButtonMenuMobile from '@/components/vue/buttons/ButtonMenuMobile.vue'

import TheHeaderMenuLayout from './TheHeaderMenuLayout.vue'
import TheHeaderMenuLayoutItem from './TheHeaderMenuLayoutItem.vue'

const { PUBLIC_CONTEXT } = import.meta.env

export default {
  name: 'TheHeaderMenu',

  components: {
    BaseImage,
    BaseButtonIcon,
    BaseLink,
    ButtonMenuMobile,
    SocialLinks,
    TheHeaderMenuLayout,
    TheHeaderMenuLayoutItem,
  },

  props: {
    primaryLinks: {
      type: Array,
      required: true,
    },

    secondaryLinks: {
      type: Array,
      required: true,
    },

    quickLinks: {
      type: Array,
      required: true,
    },

    socialLinks: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      context: PUBLIC_CONTEXT,
    }
  },

  computed: {
    subMenuData() {
      if (!this.primaryLinks || !this.primaryLinks.length) return null
      return (
        this.primaryLinks.find((link) => link._modelApiKey === 'menu_link') ||
        null
      )
    },
  },
  methods: {
    imgixSize,
    urlBuilder,
  },
}
</script>

<style lang="postcss">
.TheHeaderMenu__close {
  @screen sm {
    @apply hidden;
  }
}

.TheHeaderMenu__link {
  @apply mb-0;

  @screen smMax {
    padding: calc(7 / 27 * 1em) 0 calc(8 / 27 * 1em);
  }

  &.has-image {
    @apply flex items-center;
  }
}

.TheHeaderMenu__text {
  @apply flex items-end opacity-100;

  transition:
    opacity theme('transitionDuration.250')
      theme('transitionTimingFunction.smooth'),
    transform theme('transitionDuration.250')
      theme('transitionTimingFunction.smooth');

  &:active {
    @apply opacity-60;
  }

  @screen sm {
    transform: translateX(0) translateZ(0);

    .TheHeaderMenu__link:hover & {
      transform: translateX(0.75ch) translateZ(0);
    }
  }
}

.TheHeaderMenu__hover {
  @apply hidden;

  @apply lg:block lg:opacity-0 lg:transition-opacity lg:duration-250 lg:ease-smooth;

  margin-left: calc(theme('spacing.base-30') / 2);

  .TheHeaderMenu__link:hover & {
    @apply lg:opacity-100;
  }
}

.TheHeaderMenu__media {
  @apply bg-white;

  width: theme('spacing.base-54');
  padding-right: var(--base-outer-gutter);
  @apply sm:absolute sm:top-0 sm:right-0 sm:h-full sm:my-0 sm:opacity-0 sm:transition-opacity sm:duration-250 sm:ease-smooth;

  @screen sm {
    width: 50%;
    padding-top: theme('spacing.base-30');
    .TheHeaderMenu__link:hover & {
      z-index: 1;
    }
  }

  @apply sm:opacity-100;
}

.TheHeaderMenu__media-inner {
  @apply smMax:-m-base-6;

  @screen sm {
    height: 100%;

    .TheHeaderMenu__link.has-nudge & {
      position: relative;
      top: 25%;
    }
  }
}

.TheHeaderMenu__image {
  @apply w-full;

  @screen sm {
    height: 100%;
    object-fit: contain;
  }
}

.TheHeaderMenu__back {
  @apply flex items-center w-full;
  @apply sm:hidden;
}

.TheHeaderMenu__back-text {
  @apply flex-grow mr-base-36 mb-0;
}

.TheHeaderMenu__footer {
  @apply mt-base-24 ml-base-54;

  @apply sm:mt-base-42 sm:ml-0;
}

.TheHeaderMenu__footer-title {
  @apply mb-base-12;
  @apply sm:mb-base-6;
}

.TheHeaderMenu__footer-link {
  @apply block mb-0;

  @screen smMax {
    padding: calc(4 / 17 * 1em) 0 calc(5 / 17 * 1em);
  }

  @apply opacity-100 transition-opacity duration-250 ease-smooth;

  &:active {
    @apply opacity-60;
  }
}

.TheHeaderMenu__footer-text {
  display: block;

  @apply sm:transition-transform sm:duration-250 sm:ease-smooth;

  @screen sm {
    transform: translateX(0) translateZ(0);

    .TheHeaderMenu__footer-link:hover & {
      transform: translateX(0.75ch) translateZ(0);
    }
  }
}

.TheHeaderMenu__quick {
  @apply flex items-end justify-between mt-base-18;
}

.TheHeaderMenu__quick-list {
  flex-grow: 1;
}

.TheHeaderMenu__quick-link {
  display: block;
  padding: calc(7 / 13 * 1em) 0 calc(8 / 13 * 1em);
  margin-bottom: 0;
}
</style>
