<template>
  <p class="FormInfoMsg  t-nano">
    {{ copy }}
  </p>
</template>

<script>
export default {
  name: 'FormInfoMsg',

  props: {
    copy: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="postcss">
.FormInfoMsg {
  margin-top: 0.6em;
  margin-bottom: 0;
}
</style>
