<template>
  <div
    class="FormInputAndButton"
    :class="{
      'has-error': hasError,
      'is-focused': isFocused,
      'is-disabled': loading,
      [`FormInputAndButton--${theme}`]: theme,
      'FormInputAndButton--block': labelCopy,
    }"
  >
    <label
      v-if="!isLabelHidden"
      class="FormInputAndButton__label FormInputAndButton__label--block t-para"
      :for="identifier"
      >{{ labelCopy }}</label
    >

    <div class="FormInputAndButton__field t-para">
      <input
        :id="identifier"
        ref="input"
        v-bind="$attrs"
        class="FormInputAndButton__input"
        :name="identifier"
        :placeholder="isLabelHidden ? labelCopy : placeholder"
        spellcheck="false"
        autocorrect="off"
        required
        @input="$emit('input', $event.target.value)"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />

      <label
        v-if="isLabelHidden"
        class="FormInputAndButton__label FormInputAndButton__label--hidden t-nano"
        :for="identifier"
        >{{ labelCopy }}</label
      >

      <button
        class="FormInputAndButton__button t-micro"
        type="submit"
        @click.prevent="$emit('submit')"
      >
        <span class="FormInputAndButton__button-text t-link t-link--small">
          {{ buttonCopy }}
        </span>
      </button>
    </div>

    <FormErrorMsg v-if="hasError" :copy="hasError" />
    <FormInfoMsg v-if="hasInfo" :copy="hasInfo" />
  </div>
</template>

<script>
import FormErrorMsg from './FormErrorMsg.vue'
import FormInfoMsg from './FormInfoMsg.vue'

export default {
  name: 'FormInputAndButton',

  components: {
    FormInfoMsg,
    FormErrorMsg,
  },

  inheritAttrs: false,

  props: {
    theme: {
      type: String,
      default: 'none',
      validator(value) {
        return ['none', 'dark', 'alt'].includes(value)
      },
    },

    identifier: {
      type: String,
      required: true,
    },

    labelCopy: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    buttonCopy: {
      type: String,
      required: true,
    },

    isLabelHidden: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: String,
      default: '',
    },

    hasInfo: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isFocused: false,
    }
  },
}
</script>

<style lang="postcss">
.FormInputAndButton {
  @apply mb-base-24;
}

.FormInputAndButton__field {
  @apply relative flex bg-green-05 rounded-base border border-solid border-transparent mb-0;

  .FormInputAndButton--alt & {
    @apply bg-white bg-opacity-15;
  }

  transition:
    border theme('transitionDuration.250')
      theme('transitionTimingFunction.smooth'),
    opacity theme('transitionDuration.250')
      theme('transitionTimingFunction.smooth');

  .FormInputAndButton:not(.FormInputAndButton--alt) &:hover,
  .FormInputAndButton:not(.FormInputAndButton--alt).is-focused & {
    @apply border-green;
  }

  .FormInputAndButton.has-error & {
    @apply bg-red bg-opacity-10 border-red;
  }

  .FormInputAndButton.is-disabled & {
    opacity: 0.5;
    pointer-events: none;
  }
}

.FormInputAndButton__input {
  @apply flex-grow w-full;

  line-height: 2.8;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: calc(24 / 15 * 1em); /* 54px to 60px with line-height */

  &::placeholder {
    @apply text-green opacity-50 transition-opacity duration-250 ease-smooth;

    .FormInputAndButton--alt & {
      @apply text-white;
    }
  }

  &:hover {
    &::placeholder {
      @apply opacity-25;
    }
  }

  &:focus {
    &::placeholder {
      @apply opacity-0;
    }
  }
}

.FormInputAndButton__label {
  &--hidden {
    @apply absolute mb-0;

    bottom: 100%;
    padding-bottom: 0.6em;

    .FormInputAndButton__input + & {
      @apply opacity-0 invisible;

      transform: translateX(1ch) translateZ(0);
      transition:
        opacity theme('transitionDuration.250')
          theme('transitionTimingFunction.smooth'),
        visibility theme('transitionDuration.250')
          theme('transitionTimingFunction.smooth'),
        transform theme('transitionDuration.250')
          theme('transitionTimingFunction.smooth') 0.25s;
    }

    .FormInputAndButton__input:focus + &,
    .FormInputAndButton__input:not(:placeholder-shown) + & {
      @apply opacity-100 visible;

      transform: translateX(0) translateZ(0);
      transition:
        opacity theme('transitionDuration.250')
          theme('transitionTimingFunction.smooth'),
        visibility theme('transitionDuration.250')
          theme('transitionTimingFunction.smooth'),
        transform theme('transitionDuration.250')
          theme('transitionTimingFunction.smooth');
    }
  }
}

.FormInputAndButton__button {
  @apply opacity-80 transition-opacity duration-250 ease-smooth;

  padding: calc(4 / 11 * 1em) calc(22 / 11 * 1em) 0;

  .FormInputAndButton__input:valid ~ & {
    @apply opacity-60;

    &:hover {
      @apply opacity-100;
    }
  }
}

.FormInputAndButton__button-text {
  @apply block text-green font-normal;

  .FormInputAndButton--alt & {
    @apply text-white;
  }

  .FormInputAndButton__button:hover & {
    &::after {
      transform: translateX(0) translateZ(0);
    }
  }
}

.FormInputAndButton--block .FormInputAndButton__label--block {
  @apply block mb-0;

  order: -1;
  padding-bottom: 1em;
}
</style>
