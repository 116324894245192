<template>
  <div class="TheHeaderAlertBar">
    <div class="wrapper">
      <!-- Short text for mobile -->
      <div
        v-if="shortText"
        class="TheHeaderAlertBar__text t-micro sm:hidden"
        v-html="shortText"
      />

      <!-- Long text for desktop -->
      <div
        v-if="longText"
        class="TheHeaderAlertBar__text t-micro smMax:hidden"
        v-html="longText"
      />

      <BaseButtonIcon
        class="TheHeaderAlertBar__button"
        label="cross"
        shape="cross"
        size="small"
        modifier="ghost"
        theme="alt"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import BaseButtonIcon from '@/components/vue/buttons/BaseButtonIcon.vue'

export default {
  name: 'TheHeaderAlertBar',

  components: { BaseButtonIcon },

  props: {
    shortText: {
      type: String,
      default: null,
    },

    longText: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="postcss">
.TheHeaderAlertBar {
  @apply bg-green text-white z-10 w-full relative text-center py-base-12 pointer-events-auto;
  @apply smMax:py-base-12 smMax:px-base-36;
}

.TheHeaderAlertBar__text {
  p {
    font: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    margin-bottom: 0;
  }
}

.TheHeaderAlertBar__button {
  position: absolute;
  top: 50%;
  right: theme('spacing.base-6');
  transform: translateY(-50%);
}
</style>
