<template>
  <form ref="form" class="TheFooterForm">
    <FormInputAndButton
      theme="alt"
      identifier="signup"
      label-copy="Sign up for tasty recipes, exclusive product launches, and unique experiences."
      button-copy="Submit"
      type="email"
      required
      placeholder="Email Address"
      autocomplete="email"
      autocapitalize="off"
      :has-error="error"
      :has-info="info"
      :loading="loading"
      @input="onInput"
      @submit="submitForm"
    />
  </form>
</template>

<script>
import { initServiceClient } from '@/services/bge-api/client'

import FormInputAndButton from '@/components/vue/FormInputAndButton.vue'

const serviceClient = initServiceClient()

export default {
  name: 'TheFooter',

  components: {
    FormInputAndButton,
  },

  data: () => ({
    emailAddress: '',
    error: null,
    info: '',
    loading: false,
  }),

  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout)
  },

  methods: {
    onInput(value) {
      this.emailAddress = value
      if (this.error) this.error = null
    },

    async submitForm() {
      this.loading = true

      try {
        await serviceClient.post('/newsletter/subscribe', {
          email: this.emailAddress,
        })
        this.info = 'You have been subscribed.'
        this.timeout = setTimeout(() => {
          this.resetForm()
        }, 3000)
      } catch (error) {
        if (error?.response?.data) {
          this.error = error.response.data.message

          const isEmailError =
            error.response.data.code === 'INVALID_EMAIL' ||
            error.response.data.code === 'EMAIL_REQUIRED'

          if (isEmailError) this.error = 'Please add a valid email address.'
        }
        console.error(error)
      }

      this.loading = false
    },

    resetForm() {
      this.emailAddress = ''
      this.info = null
      if (this.$refs.form) this.$refs.form.reset()
    },
  },
}
</script>
