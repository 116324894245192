<template>
  <div
    v-if="isVisible"
    role="button"
    aria-label="Close"
    class="absolute bg-opacity-75 left-0 top-0 -z-1 w-full h-full text-white bg-black flex items-center justify-center"
    :class="{'BaseOverlay--opacity-0': disableOpacity}"
    @click="$emit('close')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseOverlay',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    disableOpacity: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
