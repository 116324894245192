<template>
  <button :class="{ 'is-active': isMenuOpen }" class="ButtonMenu" type="button">
    <slot />

    <BaseShape class="ButtonMenu__icon" icon="down" />
  </button>
</template>

<script>
import BaseShape from '@/components/vue/BaseShape.vue'

export default {
  name: 'ButtonMenu',

  components: {
    BaseShape,
  },

  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss">
.ButtonMenu {
  @apply flex items-baseline;
}

.ButtonMenu__icon {
  @apply inline-block opacity-50 transition-opacity duration-250 ease-smooth;

  width: theme('spacing.base-12');
  height: theme('spacing.base-12');
  margin-left: theme('spacing.base-6');
  outline: 1px solid transparent;
  transform: translateY(0) translateZ(0) rotate(0.01deg); /* sub-pixel hack to stop judder */
  transition: transform theme('transitionDuration.500')
    theme('transitionTimingFunction.in-out-circ');

  .ButtonMenu.is-active & {
    transform: translateY(3px) translateZ(0) rotate(-0.5turn);
  }
}
</style>
