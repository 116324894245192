<template>
  <button
    class="ButtonMenuMobile"
    type="button"
  >
    <span class="ButtonMenuMobile__text">
      <slot />
    </span>

    <BaseButtonIcon
      class="ButtonMenuMobile__icon"
      tag="span"
      label="next"
      shape="next"
      size="small"
    />
  </button>
</template>

<script>
import BaseButtonIcon from './BaseButtonIcon.vue'

export default {
  name: 'ButtonMenuMobile',

  components: {
    BaseButtonIcon,
  },
}
</script>

<style lang="postcss">
.ButtonMenuMobile {
  @apply flex items-center justify-between w-full;
}
</style>
