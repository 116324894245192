<template>
  <BaseButtonIcon
    class="ButtonAccounts"
    :class="{ 'is-open': isOpen }"
    label="Visit Accounts Area"
    shape="user"
    size="smallPlusToMedium"
    modifier="ghost"
    theme="mustard"
  />
</template>

<script>
import BaseButtonIcon from './BaseButtonIcon.vue'

export default {
  name: 'ButtonAccounts',
  components: {
    BaseButtonIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss">
.ButtonAccounts {
  @screen sm {
    margin-left: 0.15em;
    @apply mr-base-12;
  }
  &:hover,
  &:focus {
    @apply text-black;
  }
  .BaseButtonIcon__svg {
    padding: 3px 0 0 3px;
  }
}
</style>
