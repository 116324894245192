<template>
  <button
    type="button"
    class="TheHeaderBurger"
  >
    <span
      aria-hidden="true"
      class="TheHeaderBurger__icon"
    />
  </button>
</template>

<script>
export default {
  name: 'TheHeaderBurger',
}
</script>

<style lang="postcss">
.TheHeaderBurger {
  @apply block p-base-18 -m-base-18;

  @apply sm:hidden;
}

.TheHeaderBurger__icon {
  --burger-width: 20px;
  --burger-height: 2px;
  --burger-gutter: 4px;

  @apply relative select-none;

  &,
  &::before,
  &::after {
    @apply block text-left bg-current;

    width: var(--burger-width);
    height: var(--burger-height);
    outline: 1px solid transparent;
    transform: translate3d(0, 0, 0);
  }

  &::before,
  &::after {
    content: "";
    @apply absolute top-0;
  }

  &::before {
    transform: translate3d(0, calc(-1 * (var(--burger-height) + var(--burger-gutter))), 0);
  }

  &::after {
    transform: translate3d(0, calc(var(--burger-height) + var(--burger-gutter)), 0);
  }
}
</style>
