<template>
  <div class="TheHeaderLogo">
    <BaseLink
      class="TheHeaderLogo__word  TheHeaderLogo__word--text"
      slug="/"
      @click.native="$emit('close-menu')"
    >
      <BaseSvg
        class="TheHeaderLogo__text"
        title="Back to homepage, Big Green Egg Logo"
        icon="logo-text"
        :vb-width="208"
        :vb-height="31"
      />
    </BaseLink>

    <BaseLink
      class="TheHeaderLogo__word  TheHeaderLogo__word--stack"
      slug="/"
      @click.native="$emit('close-menu')"
    >
      <BaseSvg
        class="TheHeaderLogo__stack"
        title="Back to homepage, Big Green Egg Logo"
        icon="logo-stack"
        :vb-width="79"
        :vb-height="68"
      />
    </BaseLink>

    <BaseLink
      class="TheHeaderLogo__icon"
      slug="/"
      @click.native="$emit('close-menu')"
    >
      <BaseSvg
        class="TheHeaderLogo__egg"
        title="Back to homepage, Big Green Egg Logo"
        icon="logo-egg"
        :vb-width="25"
        :vb-height="38"
      />
    </BaseLink>
  </div>
</template>

<script>
import BaseLink from '../vue/BaseLink.vue'
import BaseSvg from '../vue/BaseSvg.vue'

export default {
  name: 'TheHeaderLogo',

  components: {
    BaseLink,
    BaseSvg,
  },
}
</script>

<style lang="postcss">
.TheHeaderLogo {
  position: relative;
  z-index: 1;
}

.TheHeaderLogo__word {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @apply p-base-18 opacity-100 transition-opacity duration-500 ease-smooth delay-250;

  .TheHeader.has-scrolled & {
    @apply opacity-0 duration-250 delay-250;
  }

  &--text {
    @apply hidden;

    @apply lg:block;
  }

  &--stack {
    @apply lg:hidden;
  }
}

.TheHeaderLogo__text {
  width: 208px;
  height: 31px;
}

.TheHeaderLogo__stack {
  width: 72px;
  height: 62px;

  @screen md {
    width: 79px;
    height: 68px;
  }
}

.TheHeaderLogo__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @apply p-base-18 opacity-0 transition-opacity duration-400 ease-smooth;

  .TheHeader.has-scrolled & {
    @apply opacity-100 delay-500;
  }
}

.TheHeaderLogo__egg {
  width: 18px;
  height: 27px;

  @screen sm {
    width: 25px;
    height: 38px;
  }
}
</style>
